import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiAdminService } from './api-admin.service';
import { ApiGeneralService } from './api-general.service';
import { ApiMemberService } from './api-member.service';
import { CoreModule } from '../core/core.module';
import { ApiMobileService } from './api-mobile.service';
import { TimeZonesService } from './timezones.service';
import { ChatService } from './chat.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DndDirective } from './dnd.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImportUsersDialogComponent } from './import-users-dialog/import-users-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiSchedulingService } from './api-scheduling.service';

@NgModule({
    declarations: [
        DndDirective,
        ImportUsersDialogComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    providers: [
        ApiAdminService,
        ApiGeneralService,
        ApiMemberService,
        ApiMobileService,
        ApiSchedulingService,
        TimeZonesService,
        ChatService,
    ],
    exports: [
        DndDirective,
        ImportUsersDialogComponent
    ],
})
export class ShareModule { }
