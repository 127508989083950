import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import IApi from './IApi';

@Injectable()
export class ApiSchedulingService implements IApi {
    constructor(
        private http: HttpService,
        private router: Router,
    ) {}

    send(url, get = null, post = null) {
        return this.http.send(environment.API_BASE_URL + '/scheduling/' + url, get, post).catch(er => {
            if (er['status'] == 401) {
                this.router.navigate(['/scheduling/login']);
            }
        });
    }

    to (url, get_params = {}, use_token = true) {
        return this.http.to(environment.API_BASE_URL + '/scheduling/' + url, get_params, use_token);
    }
}
