<div class="container">
    <div class="title">
        <h1 mat-dialog-title>Import Users</h1>
        <button class="cross" mat-icon-button [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="description">
        <ng-container *ngIf="!loading; else loadingTemplate">
            <label for="importProducts" dnd (fileDropped)="onFileUpload($event)" class="import_block">
                <mat-icon>upload</mat-icon>
                <span>Drag and Drop</span>
            </label>
        </ng-container>
        <ng-template #loadingTemplate>
            <label class="import_block">
                <span>
                    <mat-spinner diameter="36"></mat-spinner>
                </span>
            </label>
        </ng-template>
        <input style="display: none;" id="importProducts" #fileInput
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file" (change)="onFileUpload($event); fileInput.value = null;">
        <article class="errors" *ngIf="errors.length > 0">
            <span *ngFor="let error of errors">{{error}}</span>
        </article>
    </div>
</div>