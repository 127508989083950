import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import IApi from './IApi';

@Injectable()
export class ApiAdminService implements IApi {
    constructor(private http: HttpService) {}

    send(url, get = null, post = null) {
        return this.http.send(environment.API_BASE_URL + '/admin/' + url, get, post);
    }

    to (url, get_params = {}, use_token = true) {
        return this.http.to(environment.API_BASE_URL + '/admin/' + url, get_params, use_token);
    }
}
